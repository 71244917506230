import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B3White } from "../../styles/helpers"

const Quote = ({ data }) => {
  const imageDisplay = getImage(
    data.quoteImage.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.quoteImage.altText
  return (
    <StyledDiv>
      <div className="wrapper">
        <div className="content">
          <div
            className="content__wrapper"
            dangerouslySetInnerHTML={{ __html: data.quoteContent }}
          />
        </div>
      </div>
      <div className="bg-image">
        <GatsbyImage
          image={imageDisplay}
          alt={imageAlt}
          layout="fullWidth"
          formats={["auto", "webp", "avif"]}
        />
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  position: relative;
  height: 30rem;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 40rem;
  }

  @media (min-width: 1025px) {
    height: 52rem;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;

    @media (min-width: 768px) {
      width: 60%;
      padding: 2rem;
    }

    @media (min-width: 1025px) {
      width: 60%;
      padding: 5rem 7.5rem;
    }

    p {
      ${B3White};
      margin: 0;
    }
  }

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .gatsby-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100%);
      height: 100%;

      img {
        width: 100% !important;
      }
    }
  }
`

export default Quote

import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/financial/Hero"
import Intro from "../components/financial/Intro"
import MainContent from "../components/financial/MainContent"
import ExternalLinks from "../components/financial/ExternalLinks"
import Quote from "../components/financial/Quote"

const financial = props => {
  const hero = props?.data?.hero?.template?.financialWellness
  const intro = props?.data?.intro?.template?.financialWellness
  const mainContent = props?.data?.mainContent?.template?.financialWellness
  const externalLinks = props?.data?.externalLinks?.template?.financialWellness
  const quote = props?.data?.quote?.template?.financialWellness
  return (
    <Layout>
      <Seo title="Financial Wellness" />
      <Hero data={hero} />
      <Intro data={intro} />
      <MainContent data={mainContent} />
      {/* <ExternalLinks data={externalLinks} /> */}
      <Quote data={quote} />
    </Layout>
  )
}

export const financialQuery = graphql`
  query financialTempPage($id: String!) {
    # seoInfo: wpPage(id: { eq: $id }) {
    #   seoFields {
    #     swbThemeDescription
    #     swbThemeMetaTitle
    #     swbThemeImage {
    #       localFile {
    #         relativePath
    #       }
    #     }
    #   }
    # }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FinancialWellness {
          templateName
          financialWellness {
            heroImageTitle
            heroImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    intro: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FinancialWellness {
          templateName
          financialWellness {
            introTitle
            introBigContent
            introSmallContent
            introIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
        }
      }
    }

    mainContent: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FinancialWellness {
          templateName
          financialWellness {
            mainContentWysiwyg
          }
        }
      }
    }

    externalLinks: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FinancialWellness {
          templateName
          financialWellness {
            externalLinks {
              mainTitle
              links {
                title
                url
              }
            }
          }
        }
      }
    }

    quote: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FinancialWellness {
          templateName
          financialWellness {
            quoteContent
            quoteImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default financial

import React from "react"
import styled from "styled-components"
import { B1Black, colors, H3DarkBlue, medWrapper } from "../../styles/helpers"

const ExternalLinks = ({ data }) => {
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="links">
          {data.externalLinks.map((links, index) => {
            return (
              <LinkSection key={index}>
                <div>
                  <h2>{links.mainTitle}</h2>
                </div>
                <ul>
                  {links.links.map((link, index) => (
                    <li key={index}>
                      <a target="_blank" rel="noreferrer" href={link.url}>
                        {link.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </LinkSection>
            )
          })}
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`

const LinkSection = styled.div`
  width: 100%;
  margin-bottom: 5rem;

  h2 {
    ${H3DarkBlue};
  }

  ul {
    width: 100%;

    li,
    a {
      ${B1Black};
      text-transform: uppercase;
    }

    a:hover {
      color: ${colors.colorAccent};
    }
  }
`

export default ExternalLinks

import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1DarkBlue,
  H2DarkBlue,
  medWrapper,
  B3DarkBlue,
} from "../../styles/helpers"

const Intro = ({ data }) => {
  const imageDisplay = getImage(
    data.introIcon.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.introIcon.altText
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h2>
            <span className="title__icon">
              <GatsbyImage
                image={imageDisplay}
                alt={imageAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
            </span>{" "}
            <span className="title__text">{data.introTitle}</span>
          </h2>
        </div>
        <div
          className="intro-big-content"
          dangerouslySetInnerHTML={{ __html: data.introBigContent }}
        />
        {data.introSmallContent && (
          <div
            className="intro-small-content"
            dangerouslySetInnerHTML={{ __html: data.introSmallContent }}
          />
        )}
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  background-color: rgba(221, 211, 201, 0.4);

  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;

    h2 {
      ${H2DarkBlue};
      display: flex;
      align-items: center;

      .title__icon {
        display: inline-block;
        width: 9.2rem;
      }

      .title__text {
        padding-left: 2.5rem;
      }
    }
  }

  .intro-big-content {
    width: 100%;

    p {
      ${B3DarkBlue};
    }
  }

  .intro-small-content {
    width: 100%;

    p {
      ${B1DarkBlue};
    }
  }
`

export default Intro
